import React from 'react'
import './homeMain.css';
import Fade from 'react-reveal/Fade';
/* import Parallax from 'react-rellax'; */
import { ScrollRotate } from 'react-scroll-rotate';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Ring1 from '../assets/images/hieroRingLarge.png';
import LogoRing from '../assets/images/hiero_logoRing.png';
import LogoCenter from '../assets/images/hiero_logoCenter.png';
import CodexImage from '../assets/images/hieroCodexPlaceholder.png';
//
import TwitterLogo from '../assets/images/logos/twitterLogo2.svg';
import DiscordLogo from '../assets/images/logos/discordLogo.png';
import OpenSeaLogo from '../assets/images/logos/opensea.svg';
//
import SampleArtifactImage1 from '../assets/images/artifacts/hieroArtifactSample1c.PNG';
import SampleArtifactImage2 from '../assets/images/artifacts/hieroArtifactSample2c.PNG';
import SampleArtifactImage3 from '../assets/images/artifacts/hieroArtifactSample3c.PNG';

/* import {Controller, Scene} from 'react-scrollmagic'; */


const linksList = 
[
    'https://twitter.com/HieroArt',
    'https://discord.com/',
    'https://opensea.io/'
];

const introductionParagraphs = 
[
`
The technological and cultural advancements of modernity are the culmination of human
progress, struggle, and triumph over thousands of years of civilization. Only a fragment
of these memories and experiences remain, much being absorbed and lost to time.
`,
`
This loss of historical knowledge coupled with the enhancement of society shares a similar
process to the deflationary tokenomic design popular in DeFi, in which a decreased supply
with sustained demand increases the value of the individual asset. 
HIERO is an artistic rendition that merges these two worlds, exhibiting a deflationary model 
of history through NFTs.
`,
`
The presentation of this "deflationary history" is a combination of generative and custom visuals, 
puzzles, and play-to-earn mechanics. The primary aesthetic element of HIERO is writing systems 
for languages spanning a timeline beginning at the present day and traveling back to 3000 BCE. 
`
];

/* Along this journey,

increasingly difficult journey further back in time */
/* An ancient, forgotten civilization foretold of the arrival of NFTs in the 21st century. 
As humans begin seeking new worlds beyond Earth, pieces of history are to be preserved
*/

const transmutationParagraph0 = 
`
The first HIERO phase begins at the present day with 10,000 HIERO NFT artifacts. 
In order to progress to the next phase and to acquire rarer HIERO artifacts, a group
of matching artifacts from one phase must be transmuted. Transmutation burns the matching
group and produces a rarer artifact from further back in history!
`;


const transmutationCodexParagraphs =
[
`
The HIERO Transmutation Codex is a visual representation of the entire HIERO Saga.
The Codex holds the secrets needed to travel back in time.
Decipher the Codex to unlock the transmutation combinations for your artifacts and proceed to the next phase! 
`,
`
The Codex will be fractionalized into HIERO Transmutation Codex Fragments (HTCF). 
Once you have discovered the key to acquiring a matching group of artifacts, use HTCF to 
complete the transmutation process. HTCF used for transmutations will be removed from circulation.
HTCF will likely have additional post-launch use cases such as special events and 
involvement in future HIERO Sagas.
`,
`
The Codex image itself may morph and adapt to the progression of the Saga.
In the rare case that the Codex is purchased after a buyout, a secret event occurs
to conclude the Saga.
`
];


const tokenomicsTextList = 
[
`
50% of the funds raised from the initial artifacts sale will be deposited in passive yield
platforms. 
The yield rewards will be used for events as well as to buyback and force transmute 
artifacts, creating continual deflation and progression of the Saga. Convex Finance and 
Uniswap V3 LP are the targets for the yield position.
`,
`
A 2-step launch structure will be used to reduce gas costs. The launch will be broken up
into registration (first-come-first-serve, gas war, but with reduced gas cost) 
and then mintage (only for those that have registered, higher gas cost but without need
to rush).
`,
`
Bot and supply imbalance reduction measures are incorporated into the launch. 
Along with the standard maximum mint per transaction, the launch also includes
maximum mint per address and a transaction origin check to prevent contract buys.
`,
`
OpenSea royalties and HTCF liquidity pool fees will be used for funding
future post-launch events and development.
`,
`
Mint price will be between 0.08 - 0.1 eth per Phase 1 artifact.
`,
];

const listTokenomics = tokenomicsTextList.map((text, index) => 
    <Fade top key={index}>
        <li><p>{text}</p></li>
    </Fade>
);

const phasesList = 
[
    {
        phase: 'PHASE',
        artifactCount: 'ARTIFACTS',
        timePeriod: 'TIME PERIOD'
    },
    {
        phase: '1',
        artifactCount: '10000',
        timePeriod: '1750 CE - present'
    },
    {
        phase: '2',
        artifactCount: '2000',
        timePeriod: '1500 CE - 1750 CE'
    },
    {
        phase: '3',
        artifactCount: '400',
        timePeriod: '1000 CE - 1500 CE'
    },
    {
        phase: '4',
        artifactCount: '80',
        timePeriod: '500 CE - 1000 CE'
    },
    {
        phase: '5',
        artifactCount: '16',
        timePeriod: '0 CE - 500 CE'
    },
    {
        phase: '6',
        artifactCount: '8',
        timePeriod: '500 BCE - 0 CE'
    },
    {
        phase: '7',
        artifactCount: '4',
        timePeriod: '1000 BCE - 500 BCE'
    },
    {
        phase: '8',
        artifactCount: '2',
        timePeriod: '2000 BCE - 1000 BCE'
    },
    {
        phase: '9',
        artifactCount: '1',
        timePeriod: '3000 BCE - 2000 BCE'
    }
];

const listPhases = phasesList.map((phaseData, index) => 
    <Fade top key={index}>
    <div className="timePeriodContainer">
        <div className="timePeriodSubcontainer">
            <h1>{phaseData.phase}</h1>
        </div>
        <div className="timePeriodSubcontainer">
            <h1>{phaseData.artifactCount}</h1>
        </div>
        <div className="timePeriodSubcontainer">
            <h1>{phaseData.timePeriod}</h1>
        </div>
    </div>
    </Fade>
);

const statBoxList = 
[
    {
        h1: '7',
        h2: 'REGIONS'
    },
    {
        h1: '9',
        h2: 'PHASES OF HISTORY'
    },
    {
        h1: '153',
        h2: 'WRITTEN LANGUAGE GROUPS'
    },
    {
        h1: '>45K',
        h2: 'INDIVIDUAL CHARACTERS'
    }
]

const listStatBoxes = statBoxList.map((statBox, index) => 
    <div className="statBoxInner" key={index}>
        <h1>{statBox.h1}</h1>
        <h2>{statBox.h2}</h2>
    </div>    
);

const sampleArtifactsList = [
    {
        image: SampleArtifactImage1,
        legend: "Phase 4 Golden Silk: Greek Capital Letter Omega"
    },
    {
        image: SampleArtifactImage2,
        legend: "Phase 1 Eruption: Latin Capital Letter A"
    },
    {
        image: SampleArtifactImage3,
        legend: "Phase 1 Northern Lights: Hanzi Ideograph For Union (4F1A)"
    },   
];

const listSampleArtifacts = sampleArtifactsList.map((sampleArtifactData, index) => 
    <div key={index}>
        <img src={sampleArtifactData.image} alt={"Sample Artifact Image ".concat(index)}/>
        <p className="legend">{sampleArtifactData.legend}</p>
    </div>  
);

const HomeMain = () => {
    return (
        <>

            <div className="home0Container">
                <div className="logoContainerOuter">
                    <img src={LogoRing} className="logoRingX" alt="logo_ring"></img>
                    <Fade>
                        <img src={LogoCenter} className="logoCenterX" alt="logo_center"></img>
                    </Fade>                    
                </div>
                {/*                 
                <div className="logoContainer">
                    <img src={LogoRing} className="logoRing" alt="logo_ring"></img>
                    <Fade>
                        <img src={LogoCenter} className="logoCenter" alt="logo_center"></img>
                    </Fade>
                </div> */}
                {/* <ScrollRotate method="perc"><h1>𓅕</h1></ScrollRotate> */}
                <Fade bottom>
                <h1>H I E R O</h1>
                <h2>A DEFLATIONARY NFT JOURNEY <br/> THROUGH HISTORY</h2>
                <div>
                <a href={linksList[0]} target="_blank" rel="noreferrer"><img className="linkIconImageHeader" src={TwitterLogo} alt="Twitter"></img></a>
                <a href={linksList[1]} target="_blank" rel="noreferrer"><img className="linkIconImageHeader" src={DiscordLogo} alt="Discord"></img></a>
                <a href={linksList[2]} target="_blank" rel="noreferrer"><img className="linkIconImageHeader" src={OpenSeaLogo} alt="OpenSea"></img></a>
                </div>
                </Fade>
            </div>


            <div className="home1Container">
                <Fade top>
                <h1>YOUR SAGA BEGINS</h1>
                <p>{introductionParagraphs[0]}</p>
                <p>{introductionParagraphs[1]}</p>
                <p>{introductionParagraphs[2]}</p>
                </Fade>
            </div>

            <div className="statAndCarouselContainerOuter">
                <div className="statContainer">
                    {listStatBoxes}
                </div>
                <div className="carouselContainerOuter">
                    <Carousel className="carouselContainer" showStatus={false} infiniteLoop={true}>
                        {listSampleArtifacts}
                    </Carousel>
                </div>
            </div>

            <div className="home1Container">
                <Fade top>
                <h1>TRAVEL BACK IN TIME</h1>
                <p>{transmutationParagraph0}</p>
                </Fade>
                <br/>
                <br/>
                <div className="phasesContainer">{listPhases}</div>
            </div>

            <div className="ringContainerOuter">
                <div className="ringTextContainer">
                    <Fade top>
                    <h1>THE TRANSMUTATION CODEX<br/>SHOWS YOUR PATH</h1>
                    <p>{transmutationCodexParagraphs[0]}</p>
                    </Fade>
                    <div className="codexImageContainer">
                        <img src={CodexImage} className="codexImage" alt="codexImage"></img>
                        <p>THE CODEX WILL BE REVEALED SOON</p>
                    </div>
                    <Fade top>
                    <p>{transmutationCodexParagraphs[1]}</p>
                    <p>{transmutationCodexParagraphs[2]}</p>
                    </Fade>
                    {/* <h1>𓅕</h1> */}
                </div>
                <div className="ringImageContainer">
                    <ScrollRotate method="perc" loops={1.5}>
                        <img src={Ring1} className="ringImage" alt="ringImage"></img>
                    </ScrollRotate>
                </div>
            </div>

            <div className="tokenomicsContainerOuter">
                <div className="tokenomicsContainer">
                    <Fade top>
                    <h1>ADDITIONAL DETAILS</h1>
                    </Fade>
                    <ul>{listTokenomics}</ul>
                    <br/>
                    <br/>
                </div>
            </div>

            <div className="footerContainer">
                <a href={linksList[0]} target="_blank" rel="noreferrer"><img className="linkIconImageFooter" src={TwitterLogo} alt="Twitter"></img></a>
                <a href={linksList[1]} target="_blank" rel="noreferrer"><img className="linkIconImageFooter" src={DiscordLogo} alt="Discord"></img></a>
                <a href={linksList[2]} target="_blank" rel="noreferrer"><img className="linkIconImageFooter" src={OpenSeaLogo} alt="OpenSea"></img></a>
                <h2>𓅕</h2>
                <h2>HIERO<br/>A CryptoKhan Project <br/> 2021 </h2>
            </div>

        </>
    )
}

export default HomeMain
