import './App.css';
import HomeMain from './components/homeMain';

function App() {
  return (
      <HomeMain/>
  );
}

export default App;
